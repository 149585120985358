import React from "react";
import { Typography } from '@mui/material';


export function Dashboard() {
    return (
        <div>
            <Typography sx={{ color: 'white' }} variant='h4'>Please Verify Email</Typography>
        </div>
    );
}